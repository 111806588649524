.ep-header *,
.ep-header *:before,
.ep-header *:after{
  box-sizing:border-box
}

html, body{
  margin: 0;
  padding: 0;
}

a, button{
  outline: none;
}

/* remove the outline in firefox */
::-moz-focus-inner {
  border: 0;
}

/* Hide an element without display none */
.sr-only {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 0;
  left: -100px;
  width: 0;
  height: 0;
  white-space: nowrap;
}

.nodisplay{
  display: none !important;
}

.novisible{
  opacity: 0;
}