/* Breakpoints -----------------
// -------------------------------------------- */

@mixin smallscreen {
  @media (max-width: 480px) { @content; }
}
@mixin mobile {
  @media (min-width: 480px) { @content; }
}
@mixin phone {
  @media (min-width: 640px) { @content; }
}
@mixin tablet {
  @media (min-width: 750px) { @content; }
}
@mixin desktop {
  @media (min-width: 1020px) { @content; }
}
@mixin fullscreen {
  @media (min-width: 1200px) { @content; }
}

/* Effect Animation -----------------
// -------------------------------------------- */

@mixin transition($element: all, $duree : .3s) {
  transition:$duree all ease-in-out;
}

@mixin animate($name: anim, $duree:.3s){
  animation:$name $duree infinite alternate;
}